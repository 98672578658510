import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TableReservationPageRoutingModule } from './table-reservation-routing.module';

import { TableReservationPage } from './table-reservation.page';
import { TranslateModule } from '@ngx-translate/core';

import { PipeModule } from '../../../core/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TableReservationPageRoutingModule,
    TranslateModule,
    PipeModule
  ],
  declarations: [TableReservationPage],
  exports: [TableReservationPage]
})
export class TableReservationPageModule { }
