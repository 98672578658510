import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.page.html',
  styleUrls: ['./tour.page.scss'],
})
export class TourPage implements OnInit {

  constructor(public popover: PopoverController, public appService:AppServiceService) {

  }

  ngOnInit() {

  }

  ngDoCheck() {
    // display touchable area to change favourite restaurant
    let ele = document.getElementById('enable-tour-container');
    if (ele) {
      let bounding = ele.getBoundingClientRect();
      if (bounding) {
        let holeEle = document.getElementById('secret-div');
        if (holeEle) holeEle.setAttribute('style', 'top:' + ((bounding.top) + 'px;left : ' + (bounding.left + 'px;width:') + ((bounding.width + 1) + 'px; height: ') + ((bounding.height + 1) + 'px')));
      }
    }
  }

  dismiss() {
    this.appService.tourModeOn = false;
    this.popover.dismiss();
  }

}
