import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CrossSellingPage } from './cross-selling.page';

const routes: Routes = [
  {
    path: '',
    component: CrossSellingPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrossSellingPageRoutingModule { }
